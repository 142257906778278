import React, {FC, Fragment, ReactNode} from "react";
import Heading from "./Heading";
import HyperLink from "./HyperLink";
import Editor from "./Editor";
import Accordion from "./Accordion";
import ImageUpload from "./ImageUpload";
import SliderComponent from "./SliderComponent";
import Button from "./Button";
import Video from "./Video";
import PostsComponent from "./PostsComponent";
import {TranslationsType} from "../../types/homePageTypes";
import FormBuilderComponent from "./FormBuilderComponent";
import {ICurrencies} from "../ForCWV/Wrappers/MainLayout";
import cls from "./builder.module.scss"

interface IRenderComponent {
    data: any
    dbName: string
    translationsData: TranslationsType,
    selectedLocale: string,
    backOrderValue: string,
    currencies: Array<ICurrencies>;
}

const RenderBuilderComponent: FC<IRenderComponent> = (
    {
        data,
        dbName,
        translationsData,
        selectedLocale,
        currencies,
        backOrderValue
    }
) => {
    const VideoComponentLayout = ({ children }: { children: ReactNode }) => {
        const videoToggle = {
            "true": <div className={cls.video_wrapper}>{children}</div>,
            "false": <Fragment>{children}</Fragment>,
        }
        return videoToggle[`${!!data?.fullScreen}`]
    }
    const builderComponents:Record<string, React.JSX.Element> = {
        Heading: <Heading item={data}/>,
        HyperLink: <HyperLink item={data}/>,
        Editor: <Editor item={data}/>,
        Accordion: <Accordion item={data}/>,
        ImageUpload: <ImageUpload item={data} dbName={dbName}/>,
        Slider: <SliderComponent dbName={dbName} item={data}/>,
        Button: <Button item={data} selectedLocale={selectedLocale} currencies={currencies} backOrderValue={backOrderValue}/>,
        Video: <VideoComponentLayout><Video item={data}/></VideoComponentLayout>,
        Posts: <PostsComponent item={data} translationsData={translationsData}/>,
        FormBuilder: <FormBuilderComponent item={data}/>,
        Grid: <></>,
    }
    return builderComponents[data?.content as string] || <></>
}

export default RenderBuilderComponent;