import React, {FC} from "react";
import Link from "next/link";
import cls from "./posts.module.scss"
import Image from "next/image";
import {IPostCategory} from "../../types/posts";
import {TranslationsType} from "../../types/homePageTypes";

interface IProps {
    post: { image: string, rightDay: string, slug: string, name: string };
    category: IPostCategory;
    translationsData: TranslationsType;
}

const PostCard: FC<IProps> = (
    {
        post: {rightDay, image, slug, name},
        category,
        translationsData
    }
) => {

    return (
        <Link href={`/posts/${category?.slug}/${slug}`}>
            <a>
                <div className={cls.postCard}>
                    <div className={cls.post_card_image}>
                        <Image
                            src={image}
                            alt={name}
                            layout="fill"
                            loading="lazy"
                            // priority
                        />
                    </div>
                    <div className={cls.post_card_info}>
                        <div className={cls.post_card_name}>
                            <span>{name}</span>
                        </div>
                        <div className={cls.post_card_read_data}>
                            <div className={cls.post_card_date}>{rightDay}</div>
                            <div className={cls.post_card_read_more}>
                                {translationsData?.ReadMore}
                            </div>
                        </div>
                    </div>
                </div>
            </a>
        </Link>
    );
};

export default PostCard;